export const apiDomain = import.meta.env.VITE_APP_API_URL;

export const STATE_LOGIN_INITIAL = 0;
export const STATE_LOGIN_WELCOME = 1;
export const STATE_LOGIN_EMAIL_CHECKING = 2;
export const STATE_LOGIN_EMAIL_SUCCESS = 3;
export const STATE_LOGIN_EMAIL_FAIL = 4;
export const STATE_LOGIN_EMAIL_RESEND = 5;
export const STATE_LOGIN_PASSWORD_ASK = 6;
export const STATE_LOGIN_PASSWORD_CHECKING = 7;
export const STATE_LOGIN_PASSWORD_SUCCESS = 8;
export const STATE_LOGIN_PASSWORD_FAIL = 9;

export const programSlides = [
  {
    title: 'Selected for designers',
    description: 'Photoshop, Illustrator, After Effects and other programs that perfectly suits for designers ',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/designer.mp4'
  },
  {
    title: 'Selected for 3d artists',
    description: 'Cinema 4D, Blender, and other programs that perfectly suits for 3d artists',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/3dArtist.mp4'
  },
  {
    title: 'Selected for video makers',
    description: 'Premiere Pro, After Effects and other programs that perfectly suits for video makers',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/videoMaker.mp4'
  }
];

export const licenseTypes = [
  { name: 'All', value: 'all' },
  { name: 'Free', value: 'free' },
  { name: 'Paid', value: 'paid' }
];

export const MAX_SIZE_UPLOAD_FILE = 5 * 1024 * 1024;
export const ALLOWED_FORMATS_TO_UPLOAD = [ 'image/png', 'image/jpeg', 'image/webp' ];
export const AUTH_DIGITS_COUNT = 6;
export const DELETE_ACCOUNT_REASONS = [
  {
    title: 'I have negative service experience',
    value: 'I have negative service experience'
  },
  {
    title: 'I found alternative',
    value: 'I found alternative'
  },
  {
    title: 'Your service is too expensive',
    value: 'Your service is too expensive'
  },
  {
    title: 'I can’t find a program that I would like to',
    value: 'I can’t find a program that I would like to'
  },
  {
    title: 'Other',
    value: 'Other'
  }
];

export const FAST_CATEGORY_FILTERS = [
  { label: 'All', ids: [ 0 ] },
  { label: 'Video engineers', ids: [ 8 ] },
  { label: 'Graphic', ids: [ 6 ] },
  { label: '3D', ids: [ 3, 6, 8 ] },
  { label: 'Architects & Engineers', ids: [ 6, 8 ] }
];
