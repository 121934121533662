import { createApp, nextTick } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from "vue-toastification";
import { getUserAsync } from './composables/useAuth';
import { MotionPlugin } from '@vueuse/motion';
import { vTooltip } from 'floating-vue';
import posthog from 'posthog-js';

import './assets/main.scss';

if (import.meta.env.PROD) {
  // disable console logs in production
  console.log = () => {};

  // TODO: make it in better way (move to plugin?)
  posthog.init(
    import.meta.env.VITE_APP_POSTHOG_KEY,
    {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
  );

  router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        posthog.capture('$pageview', { path: to.fullPath });
      });
    }
  });
}

const app = createApp(App);

app
  .use(router)
  .use(Toast, {
    position: 'bottom-right',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: false,
    icon: true,
    rtl: false
  })
  .use(MotionPlugin)
  .directive('focus', {
    mounted(el, { value = 'input' }) {
      el.querySelectorAll(value)?.[0]?.focus();
    }
  })
  .directive('tooltip', vTooltip);

getUserAsync().then(() => {
  app.mount('#app');
});
